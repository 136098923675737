import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import About1 from "../images/robert-beach.jpg"
import About2 from "../images/robert-with-grandson-2.jpg"
import About3 from "../images/robert-wife-beach.jpg"

const AboutContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 10rem;
`

/* Header */
const AboutHeaderContainer = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  width: 100%;
`

const AboutTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`

const AboutLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`
/* Images Wrapper */
const ImgWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
          flex-flow: row;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
`

const AboutImg = styled.img`
  width: 150px;
  height: 200px;
  object-fit: cover;
  margin-top: 1rem;
  filter: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.2));
  flex: 1;

  :nth-child(2) {
   
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  :nth-child(3) {
    object-position: 70%;
  }

  @media (min-width: 768px) {
    width: 250px;
    height: 300px;
  }
`
/* About Text */

const AboutTextContainer = styled.div`
  margin-top: 2.5rem;
  width: 90%;

  @media (min-width: 768px) {
    width: 70%;
    margin-top: 3rem;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }
`

const AboutText = styled.h2`
  font-size: 2.1rem;
  font-family: adobe-caslon-pro;
  line-height: 2.75rem;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
  }

  @media (min-width: 1250px) {
    line-height: 2.75rem;
  }
`

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <AboutContainer>
      <AboutHeaderContainer>
        <AboutTitle>About</AboutTitle>
        <AboutLine>&nbsp;</AboutLine>
      </AboutHeaderContainer>
      <ImgWrapper>
        <AboutImg src={About2} alt="Robert Whitlow with Grandson" />
        <AboutImg src={About1} alt="Robert Whitlow with family on beach" />
        <AboutImg src={About3} alt="Robert Whitlow with wife" />
      </ImgWrapper>
      <AboutTextContainer>
        <AboutText>Robert Whitlow is a best-selling author of legal novels and winner of the prestigious Christy Award for Contemporary Fiction.</AboutText>
        <AboutText>A practicing attorney, Whitlow and his wife, Kathy, have four children. They make their home in Charlotte, North Carolina.</AboutText>
      </AboutTextContainer>
    </AboutContainer>
  </Layout>
)

export default AboutPage